
export default function ViewContractInBlockExplorer({ address, chain = 'moonriver' }) {

    if (chain === 'moonriver')
        return <a href={`https://blockscout.moonriver.moonbeam.network/address/${address}/transactions`} target="blank" >
            <h3>{`View ${address}`}</h3>
        </a>
    if (chain === 'moonbase')
        return <a href={`https://moonbase.moonscan.io/address/${address}/`} target="blank" >
            <h3>{`View ${address}`}</h3>
        </a>

}