

export default function TipMe({ }) {
    return  <a
        className="App-link"
        href="https://pay.williamdoyle.ca"
        target="_blank"
        rel="noopener noreferrer"
    >
        Tip me!
    </a>
}