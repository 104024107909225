import React from "react";
import abi from "./abi";
import { ethers, BigNumber} from "ethers";
import ViewContractInBlockExplorer from "../ViewContractInBlockExplorer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MintResult from "../../MintResult";
// import Web3 from "web3";
import BN from 'bn.js'
// import * as crypto from 'crypto-browserify';
const copy = x => JSON.parse(JSON.stringify(x));


const initialState = {
    mint_to: `0xd90f7Fb941829CFE7Fc50eD235d1Efac05c58190`,
    accounts: [],
    mintResult: null,
    tokenIdToView: 0,
    viewResult: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'mint_to':
            return {
                ...state,
                mint_to: action.mint_to,
            };
        case 'expires_at':
            return {
                ...state,
                expires_at: action.expires_at,
            };
        case 'accounts':
            return {
                ...state,
                accounts: action.accounts,
            };
        case 'mintResult':
            return {
                ...state,
                mintResult: action.mintResult,
            };
        case 'tokenIdToView':
            return {
                ...state,
                tokenIdToView: action.tokenIdToView,
            };
        case 'viewResult':
            return {
                ...state,
                viewResult: action.viewResult,
            };
        default:
            return state;
    }
}

const startTimer = () => {
    const start = new Date().getTime();
    return () => (new Date().getTime() - start) / 1000;
}


// const BN = Web3.utils.BN;

// math operations with string numbers
const ssub = (s1, s2) => (new BN(s1).sub(new BN(s2))).toString();
const sadd = (s1, s2) => (new BN(s1).add(new BN(s2))).toString();
const smult = (s1, s2) => (new BN(s1).mul(new BN(s2))).toString();
const sgt = (s1, s2) => (new BN(s1).gt(new BN(s2)));
const slt = (s1, s2) => (new BN(s1).lt(new BN(s2)));

/*		hash()
 *		@in [
 *			input: string
 *				The thing to hash
 *			algo: optional string
 *				the hash algorithum to use. Defaults to SHA256
 *		]
 *		@out hash of input
 *		William Doyle
 *		pre september 6th 2021
 * */
// function hash(input, algo = 'SHA256') {
//     const hash = crypto.createHash(algo);
//     hash.update(input).end();
//     return hash.digest('hex');
// }

function hash(input){
    const raw = ethers.utils.sha256(ethers.utils.toUtf8Bytes(input))
    return hfix(raw.substring(2, raw.length ))
}

 function hfix(s) {
        let rval = s.split('');
        for (let i = 0; i < rval.length -1; i+=2){
            [rval[i], rval[i + 1]] = [rval[i + 1], rval[i]]
        }
        return rval.join('');
    }

function f_mine(hc, miner_address) {
    // const showStubs = f_showStubs(hc);
    return async () => {
        const timer = startTimer();
        const problem = (await hc.problem()).toString();
        const difficulty = parseInt((await hc.difficulty()).toString())
        console.log(`difficulty --> `, difficulty);
        let k = 0;

        const sane = hash('1') === await hc.HASH('1')// test once
        console.log(`sane --> "${sane}"`)
        console.log(hash('1'))
        console.log(await hc.HASH('1'))

        if (!sane)
        throw new Error(`sanity check failed... hashs dont match`)

        MINING_LOOP:
        for (let i = '0'; ; i = sadd(i, '1')) {
        // for (let i = '1165301'; ; i = sadd(i, '1')) {
            k++
            const h =hash(`${problem}|${i}`)
            const prefix = h.substring(0, difficulty);
            if (prefix === '0'.repeat(difficulty)) {
                // we found a solution
                console.log(`Solution? i:${i}`);
                try {
                    console.log(`found solution in ${timer()} seconds`);
                    const h_check = await hc.HASH(`${problem}|${i}`);
                    console.log(` proof: ${h_check} = HASH( "${problem}|${i}" )`);
                    return {problem, solution: i }
                    // await attemptMint(hc, miner_address, problem, i);
                } catch (e) {
                    // await showStubs();
                    throw e;
                }
                // console.log(`MINED A COIN`);
                // await showStubs();
                // return { problem, solution: i };
            }
            if (k === 4000) {
                k = 0;
                // process.stdout.write('....');
                console.log(`4000 more attempts`)
                // let problem2 = (await hc.problem()).toString();
                // if (problem2 === problem)
                //     continue MINING_LOOP;
                // problem = problem2;
                // i = '0';
            }
        }
    }
}


export default function HashCash({ contract_name, contract_description, contract_address, contract_abi }) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    async function onMintButtonPressed() {
        console.log(state);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        dispatch({ type: 'accounts', accounts });

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner()

        const contract = new ethers.Contract(contract_address, contract_abi, provider);
        const contractWithSigner = contract.connect(signer);

        const mine = f_mine(contract, state.mint_to);
        const { problem, solution } = await mine()
        console.log(`mint to --> `, state.mint_to)
        console.log(`problem --> `, problem)
        console.log(`solution --> `, solution)

        try {
            const tx = await contractWithSigner.mint(state.mint_to, problem, solution);
            dispatch({ type: 'mintResult', mintResult: copy(tx) });
        }
        catch (e) {
            dispatch({
                type: 'mintResult', mintResult: {
                    error: e.message,
                    custom_message: `the mint failed it seems. check the block explorer to be sure`
                }
            });
        }

    }


    return <div className="Contract-Interface">
        <h2>{contract_name}</h2>
        <h3>{contract_description}</h3>
        <h4 style={{backgroundColor: 'yellow'}}>You must connect to MOONBASE ALPHA to use this contract</h4>
        <ViewContractInBlockExplorer address={contract_address} chain={'moonbase'} />
        <div className="Contract-Interface-Form">
            <div className="Contract-Interface-Form-Row">
                <label>Mint To</label>
                <input
                    type="text"
                    value={state.mint_to}
                    onChange={e => dispatch({ type: 'mint_to', mint_to: e.target.value })}
                />
                <label>{state.mint_to}</label>
            </div>
            {/* <div className="Contract-Interface-Form-Row">
                <label>Expires At</label>
                <DatePicker
                    selected={new Date(state.expires_at * 1000)}
                    onChange={date => dispatch({ type: 'expires_at', expires_at: Math.floor(date.getTime() / 1000) })}
                />
                <label>{state.expires_at}</label>
            </div> */}
        </div>
        <div className="Contract-Interface-Button">
            <button onClick={onMintButtonPressed} >
                <label>Mint Token</label>
            </button>
        </div>

        <MintResult mintResult={state.mintResult} />

        <AuthenticationChecker contract_address={contract_address} contract_abi={contract_abi} />
    </div>
}

function AuthenticationChecker({ contract_address, contract_abi }) {
    const [tokenId, setTokenId] = React.useState('0');
    // const [tokenData, setTokenData] = React.useState({});
    const [tokenData, setTokenData] = React.useState(null);

    async function onViewTokenButtonPressed() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contract_address, contract_abi, provider);

        // const result = await contract.tokenDetails(tokenId);
        const result = await contract.coinDetails(tokenId);

        console.log(result);
        setTokenData(result);
    }

    return <div className="Contract-Interface" style={{backgroundColor: 'lightblue'}}>
        <h2>View A Coins Details</h2>
        <div className="Contract-Interface-Form">
            <div className="Contract-Interface-Form-Row">
                <label>Enter Token Id to view</label>
                <input
                    type="text"
                    value={tokenId}
                    onChange={e => setTokenId(e.target.value)}
                />
                <label>{tokenId}</label>
            </div>
            <div className="Contract-Interface-Form-Row">
                <button onClick={onViewTokenButtonPressed}>
                    <label>View Token</label>
                </button>
            </div>
        </div>
        <MintResult mintResult={tokenData} />
        {
            (() => {
                if ((!tokenData))
                    return <>  </>
                return <div>
                    <div>
                        <label>Proof Check: </label>{hash(`${tokenData.problem}|${tokenData.solution}`)}
                    </div>

                </div>
            })()
        }
    </div>
}

HashCash.defaultProps = {
    contract_name: 'Hash Cash Proof Of Work',
    contract_description: 'Mint a token... but first do a proof of work',
    contract_address: "0xEd8a1967a98396a4FefABE79d1dF59fF4FE6C280",
    contract_abi: abi,
}