import abi from "./abi";
import React from "react";
import { ethers } from "ethers";
import ViewContractInBlockExplorer from "../ViewContractInBlockExplorer";

export default function BasicArtworkContract() {

  const contract_address = '0x3aaB03350eF2B4c12e9A288444424cA1e2B6AC7f';
  const contract_abi = abi;

  const initialState = {
    url: `https://www.robohash.org/${new Date().getTime()}`,
    mint_to: `0xd90f7Fb941829CFE7Fc50eD235d1Efac05c58190`,
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'url':
        return {
          ...state,
          url: action.url,
        };
      case 'mint_to':
        return {
          ...state,
          mint_to: action.mint_to,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [accounts, setAccounts] = React.useState([]);
  const [mintResult, setMintResult] = React.useState({});

  return (
    <>
      <h2> Basic Artwork Contract</h2>
      <ViewContractInBlockExplorer address={contract_address} />
      <table border="1">
        {
          Object.keys(state).map(key => (
            <tr>
              <td>
                <label>{key}</label>
              </td>
              <td>
                <input
                  type="text"
                  value={state[key]}
                  onChange={e => dispatch({ type: key, url: e.target.value })}
                />
              </td>
            </tr>
          ))
        }
      </table>
      <button onClick={async () => {
        // gather information and call awardItem
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log(accounts);
        setAccounts(accounts);

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner()

        const contract = new ethers.Contract(contract_address, contract_abi, provider);
        const contractWithSigner = contract.connect(signer);

        const tx = await contractWithSigner.mint(state.mint_to, state.url);
        const copy = x => JSON.parse(JSON.stringify(x));
        setMintResult(copy(tx));

      }} > mint </button>
      <img src={state.url} alt="Sorry you can't see this beautiful image" />

      {
        (() => {
          if (typeof window?.ethereum !== 'undefined') {
            return <h3>{`Connected ${typeof window?.ethereum}`}</h3>
          }
          return <h3>Not Connected!</h3>
        })()
      }
      <table border="1">
        <tr>
          <th>Your Wallet</th>
        </tr>
        {
          accounts.map(account => (
            <tr>
              <td>{`${account}`}</td>
            </tr>
          ))
        }
      </table>

      <table border="1">
        <tr>
          <th>
            Key
          </th>
          <th>
            Value
          </th>
        </tr>
        {
          Object.keys(mintResult).map((key, index) => (
            <tr key={index}>
              <td>{key}</td>
              <td>{mintResult[key]?.toString()}</td>
            </tr>
          ))
        }
      </table>
    </>
  );
}
