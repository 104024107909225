import React from "react";
import abi from "./abi";
import { ethers, BigNumber } from "ethers";
import ViewContractInBlockExplorer from "../ViewContractInBlockExplorer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MintResult from "../../MintResult";

const copy = x => JSON.parse(JSON.stringify(x));

const initialState = {
    mint_to: `0xd90f7Fb941829CFE7Fc50eD235d1Efac05c58190`,
    expires_at: Math.floor(new Date().getTime() / 1000),
    accounts: [],
    mintResult: null,
    tokenIdToView: 0,
    viewResult: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'mint_to':
            return {
                ...state,
                mint_to: action.mint_to,
            };
        case 'expires_at':
            return {
                ...state,
                expires_at: action.expires_at,
            };
        case 'accounts':
            return {
                ...state,
                accounts: action.accounts,
            };
        case 'mintResult':
            return {
                ...state,
                mintResult: action.mintResult,
            };
        case 'tokenIdToView':
            return {
                ...state,
                tokenIdToView: action.tokenIdToView,
            };
        case 'viewResult':
            return {
                ...state,
                viewResult: action.viewResult,
            };
        default:
            return state;
    }
}

export default function AuthToken({ contract_name, contract_description, contract_address, contract_abi }) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    async function onMintButtonPressed() {
        console.log(state);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        dispatch({ type: 'accounts', accounts });

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner()

        const contract = new ethers.Contract(contract_address, contract_abi, provider);
        const contractWithSigner = contract.connect(signer);

        try {
        const tx = await contractWithSigner.mint(state.mint_to, state.expires_at);
        dispatch({ type: 'mintResult', mintResult: copy(tx) });
        }
        catch (e) {
        dispatch({ type: 'mintResult', mintResult: {
            error: e.message,
            custom_message: `the mint failed it seems. check the block explorer to be sure`
        } });
        }

    }


    return <div className="Contract-Interface">
        <h2>{contract_name}</h2>
        <h3>{contract_description}</h3>
        <ViewContractInBlockExplorer address={contract_address} />
        <div className="Contract-Interface-Form">
            <div className="Contract-Interface-Form-Row">
                <label>Mint To</label>
                <input
                    type="text"
                    value={state.mint_to}
                    onChange={e => dispatch({ type: 'mint_to', mint_to: e.target.value })}
                />
                <label>{state.mint_to}</label>
            </div>
            <div className="Contract-Interface-Form-Row">
                <label>Expires At</label>
                <DatePicker
                    selected={new Date(state.expires_at * 1000)}
                    onChange={date => dispatch({ type: 'expires_at', expires_at: Math.floor(date.getTime() / 1000) })}
                />
                <label>{state.expires_at}</label>
            </div>
        </div>
        <div className="Contract-Interface-Button">
            <button onClick={onMintButtonPressed} >
                <label>Mint Token</label>
            </button>
        </div>

        <MintResult mintResult={state.mintResult} />
        <AuthenticationChecker contract_address={contract_address} contract_abi={contract_abi} />
    </div>
}

function AuthenticationChecker({ contract_address, contract_abi }) {
    const [tokenId, setTokenId] = React.useState('0');
    const [tokenData, setTokenData] = React.useState({});

    async function onViewTokenButtonPressed() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contract_address, contract_abi, provider);

        const result = await contract.tokenDetails(tokenId);

        console.log(result);
        setTokenData(result);
    }

    return <div className="Contract-Interface">
        <div className="Contract-Interface-Form">
            <div className="Contract-Interface-Form-Row">
                <label>Enter Token Id to view</label>
                <input
                    type="text"
                    value={tokenId}
                    onChange={e => setTokenId(e.target.value)}
                />
                <label>{tokenId}</label>
            </div>
            <div className="Contract-Interface-Form-Row">
                <button onClick={onViewTokenButtonPressed}>
                    <label>View Token</label>
                </button>
            </div>
        </div>

        <MintResult mintResult={tokenData} />
        {
            (() => {
                if ((!tokenData) || (tokenData.length !== 3))
                    return <>  </>
                const expiration_date = tokenData[1];
                const now = Math.floor(new Date().getTime() / 1000);

                return <div>
                    <div>
                        <label>{BigNumber.from(expiration_date).toString()}</label>
                    </div>
                    <div>
                        <label>{` or ${(new Date(BigNumber.from(expiration_date)))}`}</label>
                        <label>{` or "${BigNumber.from(expiration_date).toString()}"`}</label>
                    </div>
                    <div>
                        <label>{now}</label>
                        <label> or {(new Date(now)).toString()}</label>
                    </div>
                    <div>
                        <label>Valid: {BigNumber.from(expiration_date).sub(BigNumber.from(now)).toString()}.</label>
                    </div>
                    <div>
                        <label>Valid: {(BigNumber.from(expiration_date).sub(BigNumber.from(now))).div('1000').div('60').div('60').div('24').toString()} days.</label>
                    </div>
                </div>
            })()
        }
    </div>
}

AuthToken.defaultProps = {
    contract_name: 'Authentication Token',
    contract_description: 'A simple token that can be used to authenticate users. Optionally set an experation date, after which the token will be considered invalid.',
    contract_address: '0xbEF801ecC672604A1c4adA4627369779Dbe4efF8',
    contract_abi: abi,
}