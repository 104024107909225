import logo from './logo.svg';
// import './App.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import React from 'react';
import TipMe from './components/TipMe';
import BasicArtworkContract from './components/BasicArtworkContract/BasicArtworkContract';
import AuthToken from './components/AuthToken/AuthToken';
import AuthToken2 from './components/AuthToken2/AuthToken2';
import HashCash from './components/HashCash/HashCash';

/*
    Todo:
      []  move abi to a separate file
      []  components to separate files
      []  Validate contracts
      []  Add a way to connect to a different network
      []  Add a way to connect to a different wallet
      []  Implement a royalty sharing contract (may need to do a factory contract for the royalty relayer contract)
*/


// const options = {
//   'Basic Artwork Contract': 'https://pastebin.com/Z0ARS8df',
//   'Artwork Contract With Royalties': 'https://pastebin.com/____', // TODO: design intermediate contract to receive and forward royalties to me and the original minter
//   'Basic NFT (no metadata)': 'https://pastebin.com/___',
//   'Custom ERC20 Contract': 'https://pastebin.com/___',
// };

const options = [
  {
    value: 'Basic Artwork Contract',
    source: 'https://pastebin.com/Z0ARS8df',
    description: 'A basic artwork contract that allows you to mint NFTs on moonriver',
    address: '',
  },
  {
    value: 'Authentication Token',
    source: 'https://pastebin.com/vWW4mgSi',
    description: 'A token used to authenticate wallets. Token cannot be transfered after mint. You may set an experation date, after which the token will be considered invalid.',
    address: '0xbEF801ecC672604A1c4adA4627369779Dbe4efF8',
  },
  {
    value: 'authentication token 2',
    source: 'https://pastebin.com/s5y6e8Zq',
    description: 'like other but better',
    address: "0x15A077182758E88f103db226cffe846630676718",
  },
  {
    value: 'Basic Artwork Contract With Hash',
    source: 'https://pastebin.com/',
    description: 'A basic artwork contract that allows you to mint NFTs on moonriver. Secured by a hash of the asset',
    address: '',
  },
  {
    value: 'Hash Cash Like Token Minting Policy',
    source: 'https://pastebin.com/7jVgPHEh',
    description: 'A proof of work minting policy for NFTs. Just for fun',
    address: '0xEd8a1967a98396a4FefABE79d1dF59fF4FE6C280', // test net
  }
]

const defaultOption = options[0];
const defaultOptionValue = options[0].value;

function App() {
  const [contractSelection, _setContractSelection] = React.useState(defaultOption);

  function setContractSelection(optionTitle) {
    _setContractSelection(options.find(option => option.value === optionTitle));
  }

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Mint an NFT on moonriver
        </p>
        <div className="warning" style={{backgroundColor: `#FF0000`}}>
          <p>
            <strong>Warning:</strong> While these contracts are on a production blockchain they are not ready for serious use. Contracts here have known bugs and will be replaced at a later date. Feel free to use these contracts but avoid using them seriously for now. Feel free to tip me to help ensure work on this project continues.
          </p>
          </div>
        <TipMe />
      </header>
      <body>
        <table border="1">
          <tr>
            <td>
              {
                contractSelection.value
              }
            </td>
            <td>
              <Dropdown
                options={options.map(option => option.value)}
                onChange={option => setContractSelection(option.value)}
                value={defaultOptionValue}
                placeholder="Select an option" />
            </td>
            <td>
              <p>
                {
                  contractSelection.description
                }
              </p>
            </td>
            <td>
              {
                <a href={contractSelection.source}
                  target="_blank"
                >
                  {contractSelection.value}
                </a>
              }
            </td>
          </tr>
        </table>
        {
          (() => {
            switch (contractSelection.value) {
              case 'Basic Artwork Contract':
                return <BasicArtworkContract />;
              case 'Authentication Token':
                // return <div>Authentication Token</div>;
                return <AuthToken/>
              case 'Basic Artwork Contract With Hash':
                return <div>Basic Artwork Contract With Hash</div>;
              case 'authentication token 2':
                return <AuthToken2 />
              case 'Hash Cash Like Token Minting Policy':
                return <HashCash/>
              default:
                <h2>Unknown selection</h2>
            }
          })()
        }
      </body>
    </div>
  );
}

export default App;