import { ethers, BigNumber } from "ethers";

export default function MintResult({ mintResult }) {

    return <div className="MintResult">
        <table border="1">
            <tr>
                <th>
                    Key
                </th>
                <th>
                    Value
                </th>
            </tr>
            {
                Object.keys(mintResult ?? {}).map((key, index) => (
                    <tr key={index}>
                        <td>{key}</td>
                        {/* <td>{mintResult[key]?.toString()}</td> */}
                        <td>{(() => {
                            if (typeof mintResult[key] === 'object') {
                                if (mintResult[key] instanceof BigNumber) 
                                    return (BigNumber.from(mintResult[key])).toString();
                                    // return (BigNumber.from(mintResult[key]).mul(BigNumber.from('1000'))).toString();
                                return JSON.stringify(mintResult[key], null, 1);
                            }
                            return mintResult[key].toString()
                        })()
                        }</td>
                    </tr>
                ))
            }
        </table>
    </div>
}